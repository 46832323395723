export const getStatusDescription = (status: string): string => {
  return StatusOptions.filter(x => x.value === status?.toString())[0]?.label
}

export interface StatusList {
  value: StatusEnum | null
  label: string
}

export enum StatusEnum {
  Created = 'Created',
  Delivered = 'Delivered',
  WrongAddress = 'WrongAddress',
  SinisterFinished = 'SinisterFinished',
  AddressOutsideUrbanPerimeter = 'AddressOutsideUrbanPerimeter',
  DamagedGood = 'DamagedGood',
  PackagingUnderAnalysis = 'PackagingUnderAnalysis',
  AbsentRecipient = 'AbsentRecipient',
  InReturnProcess = 'InReturnProcess',
  CargoTheft = 'CargoTheft',
  WithdrawObjectPostOffice = 'WithdrawObjectPostOffice',
  CargoLoss = 'CargoLoss',
  OrderPostedPostOffice = 'OrderPostedPostOffice',
  RecipientMoved = 'RecipientMoved',
  UnknownRecipient = 'UnknownRecipient',
  BadWeather = 'BadWeather',
  AccessRestrictedArea = 'AccessRestrictedArea',
  NotVisitedAddress = 'NotVisitedAddress',
  DeclinedOnDelivery = 'DeclinedOnDelivery',
  CanceledBySender = 'CanceledBySender',
  RequestAwaitingTreatment = 'RequestAwaitingTreatment',
  PleaseDisregardPreviousInformation = 'PleaseDisregardPreviousInformation',
  ReturnedToSender = 'ReturnedToSender',
  AwaitingResendNewTry = 'AwaitingResendNewTry',
  CollectionPerformed = 'CollectionPerformed',
  FinishedOrder = 'FinishedOrder',
  OrderHeldForAnalysisFiscalOffice = 'OrderHeldForAnalysisFiscalOffice',
  OperationalProblems = 'OperationalProblems',
  LackPhysicalComplement = 'LackPhysicalComplement',
  DispatchedOrder = 'DispatchedOrder',
  OrderInTransitTransferBetweenUnits = 'OrderInTransitTransferBetweenUnits',
  DeliveryProcessStarted = 'DeliveryProcessStarted',
  CheckedOrder = 'CheckedOrder',
  OrderSeized = 'OrderSeized',
  OnRoutePreparingForDelivery = 'OnRoutePreparingForDelivery',
  ReturnRefused = 'ReturnRefused',
  TransferBetweenUnits = 'TransferBetweenUnits',
  ReturnInProgressToSender = 'ReturnInProgressToSender',
}

export const StatusOptions: Array<StatusList> = [
  { value: null, label: '' },
  { value: StatusEnum.AwaitingResendNewTry, label: 'Aguardando Reenvio | Nova Tentativa' },
  { value: StatusEnum.AccessRestrictedArea, label: 'Área Restrita de Acesso' },
  { value: StatusEnum.CanceledBySender, label: 'Cancelado pelo Remetente' },
  { value: StatusEnum.CollectionPerformed, label: 'Coleta Realizada' },
  { value: StatusEnum.AbsentRecipient, label: 'Destinatario Ausente | Local Fechado' },
  { value: StatusEnum.UnknownRecipient, label: 'Destinatário Desconhecido' },
  { value: StatusEnum.RecipientMoved, label: 'Destinatário Mudou-se' },
  { value: StatusEnum.ReturnedToSender, label: 'Devolvida ao Remetente' },
  { value: StatusEnum.ReturnRefused, label: 'Devolução Recusada' },
  { value: StatusEnum.ReturnInProgressToSender, label: 'Devolução em Andamento para o Remetente' },
  { value: StatusEnum.InReturnProcess, label: 'Em Processo de Devolução' },
  { value: StatusEnum.OnRoutePreparingForDelivery, label: 'Em Rota | Preparando para entrega' },
  { value: StatusEnum.PackagingUnderAnalysis, label: 'Embalagem em Análise' },
  { value: StatusEnum.RequestAwaitingTreatment, label: 'Encomenda Aguardando Tratativa' },
  { value: StatusEnum.OrderSeized, label: 'Encomenda Apreendida' },
  { value: StatusEnum.CheckedOrder, label: 'Encomenda Conferida' },
  { value: StatusEnum.DispatchedOrder, label: 'Encomenda Despachada' },
  { value: StatusEnum.OrderInTransitTransferBetweenUnits, label: 'Encomenda em Transito | Transferencia entre unidades' },
  { value: StatusEnum.Delivered, label: 'Encomenda Entregue' },
  { value: StatusEnum.FinishedOrder, label: 'Encomenda Finalizada' },
  { value: StatusEnum.OrderPostedPostOffice, label: 'Encomenda Postada nos Correios' },
  { value: StatusEnum.OrderHeldForAnalysisFiscalOffice, label: 'Encomenda Retida para Analise | Posto Fiscal' },
  { value: StatusEnum.WrongAddress, label: 'Endereco Errado| Endereco Insuficiente | Numero Nao Localizado' },
  { value: StatusEnum.AddressOutsideUrbanPerimeter, label: 'Endereco Fora do Perímetro Urbano | Zona Rural' },
  { value: StatusEnum.NotVisitedAddress, label: 'Endereco Não Visitado' },
  { value: StatusEnum.CargoLoss, label: 'Extravio de Carga' },
  { value: StatusEnum.LackPhysicalComplement, label: 'Falta de Complemento Físico' },
  { value: StatusEnum.PleaseDisregardPreviousInformation, label: 'Favor Desconsiderar Informação Anterior' },
  { value: StatusEnum.BadWeather, label: 'Intempéries' },
  { value: StatusEnum.DamagedGood, label: 'Mercadoria Avariada' },
  { value: StatusEnum.Created, label: 'Pedido Criado | Aguardando Postagem Remetente' },
  { value: StatusEnum.OperationalProblems, label: 'Problemas Operacionais' },
  { value: StatusEnum.DeliveryProcessStarted, label: 'Processo de Entrega Iniciado' },
  { value: StatusEnum.DeclinedOnDelivery, label: 'Recusado na Entrega' },
  { value: StatusEnum.WithdrawObjectPostOffice, label: 'Retirar Objeto nos Correios' },
  { value: StatusEnum.CargoTheft, label: 'Roubo de Carga' },
  { value: StatusEnum.SinisterFinished, label: 'Sinistro Liquidado' },
  { value: StatusEnum.TransferBetweenUnits, label: 'Transferência Entre Unidades' },
]
