import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Grid, Card, CardContent, FontIcon, CardHeader, CardTitle, CardSubtitle, Text, useToggle, ListItem, CardActions } from 'react-md'
import { format, parseISO } from 'date-fns'

import { Cell, PageTitle, Actions, ButtonEdit, ConfirmDialog, ButtonLog } from 'components/core'
import { getTicketStatusDescription, TicketStatusEnum } from 'types/ticket/ticket-status'
import { DateLabel, DateTimeLabel, NumberLabel, TextLabel } from 'components/label'
import { PermissionDescriptionEnum } from 'types/system/permission'
import { getTicketTypeDescription } from 'types/ticket/ticket-type'
import { useAuth } from 'contexts/auth/auth-context'

import { handleAssign, handleGet } from './actions'

import AddComment from './components/add-comment'

const Component: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const [visibleAddCommentModal, enableAddCommentModal, disableAddCommentModal] = useToggle(false)
  const [visibleActions, enableActions, disableActions] = useToggle(false)
  const [ticketId, setTicketId] = useState<string>()
  const [ticket, setTicket] = useState<any>()
  const { user } = useAuth()

  useEffect(() => {
    document.title = 'Edição de Ticket'

    load()

    return () => {}
  }, [id])

  const load = async () => {
    const res = await handleGet(id)
    if (res) setTicket(res)
  }

  const disable = !user?.permissions.includes(PermissionDescriptionEnum.Sac) || ticket?.status == TicketStatusEnum.Closed

  return (
    <>
      <Grid>
        <Cell>
          <PageTitle title='Edição de Ticket' />

          {ticket && (
            <>
              <Card fullWidth style={{ marginBottom: '0.5rem' }}>
                <CardContent>
                  <Grid>
                    <NumberLabel name='id' label='Número' value={ticket.id} desktopSize={2} tabletSize={4} />
                    <DateTimeLabel name='createdAt' label='Data de Abertura' value={ticket.createdAt} desktopSize={3} tabletSize={4} />
                    <TextLabel name='type' label='Tipo' value={getTicketTypeDescription(ticket.type)} desktopSize={3} tabletSize={4} />
                    <TextLabel name='status' label='Status' value={getTicketStatusDescription(ticket.status)} desktopSize={3} tabletSize={4} />
                    <TextLabel name='responsibleName' label='Responsável' value={ticket.responsibleName} desktopSize={5} tabletSize={4} />
                    <DateLabel name='returnDate' label='Data de Retorno' value={ticket.returnDate} desktopSize={3} tabletSize={4} />
                  </Grid>
                </CardContent>
                <CardActions align='end'>
                  <ButtonEdit text='Ações' icon={<FontIcon>settings</FontIcon>} onClick={async () => enableActions()} disabled={disable} />
                  <ButtonLog entity='Ticket' id={id} />
                </CardActions>
              </Card>

              {ticket &&
                ticket.comments &&
                ticket.comments.map((comment: any, index: number) => (
                  <Card
                    fullWidth
                    key={index}
                    style={comment.isClient ? { marginBottom: '0.5rem', border: '0.12rem solid #c62828' } : { marginBottom: '0.5rem' }}>
                    <CardHeader>
                      <CardTitle small>{comment.userName ?? 'Sistema'}</CardTitle>
                      <CardSubtitle>{format(parseISO(comment.createdAt), "dd'/'MM'/'yyyy' 'HH:mm:ss")}</CardSubtitle>
                    </CardHeader>
                    {comment.comment && (
                      <CardContent>
                        {comment.comment?.split('\n').map((text: any, index: number) => (
                          <Text key={index}>{text}</Text>
                        ))}
                      </CardContent>
                    )}
                    {!comment.comment && <CardContent>Comentário não informado</CardContent>}
                  </Card>
                ))}
            </>
          )}
        </Cell>
      </Grid>

      <Actions visible={visibleActions} disable={disableActions}>
        <ListItem leftAddon={<FontIcon>add</FontIcon>} onClick={() => enableAddCommentModal()}>
          Adicionar Comentário
        </ListItem>
        <ListItem leftAddon={<FontIcon>assignment_ind</FontIcon>} onClick={() => setTicketId(id)}>
          Atribuir Ticket
        </ListItem>
      </Actions>

      {visibleAddCommentModal && <AddComment id={id} disableModal={disableAddCommentModal} load={load} />}

      <ConfirmDialog
        text='Deseja realmente atribuir o ticket para o usuário atual?'
        visible={ticketId ? true : false}
        confirmAction={async () => {
          setTicketId(undefined)
          if (ticketId) if (await handleAssign(ticketId)) load()
        }}
        cancelAction={async () => setTicketId(undefined)}
      />
    </>
  )
}

export default Component
