export const getCronTaskDescription = (task: string): string => {
  return CronTaskOptions.filter(x => x.value === task.toString())[0]?.label
}

export interface CronTaskOption {
  value: CronTaskEnum | null
  label: string
}

export enum CronTaskEnum {
  AlfatrackingAutomaticPickupPackingListCreate = 'AlfatrackingAutomaticPickupPackingListCreate',
  AlfatrackingSendEmailClientValidation = 'AlfatrackingSendEmailClientValidation',
  AlfatrackingPickupRequestCheckin = 'AlfatrackingPickupRequestCheckin',
  AlfatrackingPickupRequestConclude = 'AlfatrackingPickupRequestConclude',
  AlfatrackingSendDeliveryEmail = 'AlfatrackingSendDeliveryEmail',
  AlfatrackingConcludeOrderDelivery = 'AlfatrackingConcludeOrderDelivery',
  AlfatrackingConcludeOrderDevolution = 'AlfatrackingConcludeOrderDevolution',
  IndexOptimize = 'IndexOptimize',
  UploadInvoicing = 'UploadInvoicing',
  IntegrationFmClearOldData = 'IntegrationFmClearOldData',
  IntegrationEnelNotfis = 'IntegrationEnelNotfis',
  IntegrationEmailStandardXml = 'IntegrationEmailStandardXml',
  IntegrationEmailExpressXml = 'IntegrationEmailExpressXml',
  IntegrationEmailMedioRodoXml = 'IntegrationEmailMedioRodoXml',
  IntegrationBlingNotfis = 'IntegrationBlingNotfis',
  IntegrationTinyNotfis = 'IntegrationTinyNotfis',
  IntegrationCorreiosOcoren = 'IntegrationCorreiosOcoren',
}

export const CronTaskOptions: Array<CronTaskOption> = [
  { value: null, label: '' },
  {
    value: CronTaskEnum.AlfatrackingAutomaticPickupPackingListCreate,
    label: '(Alfatracking) Criação automática dos romaneios de coleta a serem realizados diariamente',
  },
  { value: CronTaskEnum.AlfatrackingSendEmailClientValidation, label: '(Alfatracking) Envio do e-mail de validação de pedido para o cliente' },
  {
    value: CronTaskEnum.AlfatrackingPickupRequestCheckin,
    label: '(Alfatracking) Processamento dos checkins de solicitação de coleta realizados pelos portadores',
  },
  {
    value: CronTaskEnum.AlfatrackingPickupRequestConclude,
    label: '(Alfatracking) Processamento das conclusões de solicitação de coleta realizados pelos portadores',
  },
  {
    value: CronTaskEnum.AlfatrackingSendDeliveryEmail,
    label: '(Alfatracking) Envio do e-mail de entrega do pedido',
  },
  {
    value: CronTaskEnum.AlfatrackingConcludeOrderDelivery,
    label: '(Alfatracking) Processamento das conclusões de entrega realizados pelos portadores',
  },
  {
    value: CronTaskEnum.AlfatrackingConcludeOrderDevolution,
    label: '(Alfatracking) Processamento das conclusões de devolução realizadas pelos portadores',
  },
  { value: CronTaskEnum.IndexOptimize, label: '(FmTransportes) Execução da rotina de desfragmentação dos índices no banco de dados' },
  { value: CronTaskEnum.UploadInvoicing, label: '(FmTransportes) Criação das planilhas e upload de faturamento' },
  { value: CronTaskEnum.IntegrationFmClearOldData, label: '(Integration) Exclusão das informações antigas da integração fm' },
  { value: CronTaskEnum.IntegrationEnelNotfis, label: '(Integration) Enel - Download de arquivos notfis' },
  { value: CronTaskEnum.IntegrationEmailStandardXml, label: '(Integration) Email Standard - Download de arquivos xml' },
  { value: CronTaskEnum.IntegrationEmailExpressXml, label: '(Integration) Email Expresso - Download de arquivos xml' },
  { value: CronTaskEnum.IntegrationEmailMedioRodoXml, label: '(Integration) Email Medio Rodo - Download de arquivos xml' },
  { value: CronTaskEnum.IntegrationBlingNotfis, label: '(Integration) Bling - Pesquisa de pedidos' },
  { value: CronTaskEnum.IntegrationTinyNotfis, label: '(Integration) Tiny - Pesquisa de pedidos' },
  { value: CronTaskEnum.IntegrationCorreiosOcoren, label: '(Integration) Correios - Pesquisa de eventos' },
]
