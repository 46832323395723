import { LayoutNavigationItem, LayoutNavigationTree } from 'react-md'

import { MenuPermission } from 'components/layout/components/menu/types'

interface getMenuProps {
  permissions: MenuPermission
  menu: LayoutNavigationTree<LayoutNavigationItem>
  getMenu: (id: string, parent: string | null, title: string, icon: string, path: string | null) => LayoutNavigationItem
}

export const getInvoicingMenu = ({ permissions, menu, getMenu }: getMenuProps) => {
  if (permissions?.invoicing.cte.fix) {
    menu['/invoicing'] = getMenu('/invoicing', null, 'Faturamento', 'wallet', null)

    if (permissions?.invoicing.cte.fix) {
      menu['/invoicing/cte/fix'] = getMenu('/invoicing/cte/fix', '/invoicing', 'Ctes Inválidos', 'build', '/invoicing/cte/fix')
    }
  }
}
