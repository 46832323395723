import React from 'react'
import { FormContext, FormContextValues } from 'react-hook-form'
import { Grid } from 'react-md'

import { PartnerIntegrationOptions } from 'types/quote/partner-integration'
import { Input, InputNumber, Select } from 'components/form'

interface ComponentProps {
  form: FormContextValues<any>
}

const Component: React.FC<ComponentProps> = ({ form }) => {
  return (
    <FormContext {...form}>
      <Grid>
        <Input name='name' label='Nome *' desktopSize={3} />
        <Select name='integration' label='Integração *' options={PartnerIntegrationOptions} desktopSize={3} tabletSize={4} />
        <InputNumber name='maximumWeightVolume' label='Peso Máximo por Volume *' desktopSize={2} tabletSize={4} />
        <InputNumber name='biggestSide' label='Maior Lado *' desktopSize={2} tabletSize={4} />
        <InputNumber name='sumSides' label='Soma dos Lados *' desktopSize={2} tabletSize={4} />
      </Grid>
    </FormContext>
  )
}

export default Component
